<template>
  <div data-tour-step-content class="flex flex-col space-y-4 w-full p-3">
    <div class="flex items-center justify-between space-x-4">
      <lf-heading-3 class="text-white font-normal" data-cy="tourStepTitle">
        {{ title }}
      </lf-heading-3>
      <span
        v-if="totalSteps > 1"
        class="text-white text-sm font-semibold"
        data-cy="tourStepStatus"
      >
        {{ currentStep }}/{{ totalSteps }}
      </span>
    </div>
    <p class="text-sm" v-html="description" data-cy="tourStepDescription"></p>
    <div
      class="w-full flex items-center"
      :class="isLastStep ? 'justify-end' : 'justify-between'"
    >
      <span
        v-if="!isLastStep"
        data-cy="skipTourButton"
        class="font-normal text-sm cursor-pointer"
        @click="skipTour"
      >
        {{ $t("COMMON.SKIP") }}
      </span>
      <lf-button
        class="bg-purple-800 text-white"
        data-cy="nextTourStepButton"
        @click="nextTourStep"
      >
        {{ isLastStep ? $t("COMMON.GOT_IT") : $t("COMMON.NEXT") }}
      </lf-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import LfButton from "@/components/ui/buttons/LfButton.vue";
import LfHeading3 from "@/components/ui/text/LfHeading3.vue";
import { computed } from "vue";
import i18n from "@/i18n";

interface Props {
  title: string;
  description: string;
  currentStep: number;
  totalSteps: number;
  skipTour: () => void;
  nextTourStep: () => void;
}

// The global variable "$t" (or anything globally declared) is not available here since
// this component is not mounted as a part of the App context, but as a standalone
// component with "defineComponent" inside "useTippy" hook.
const $t = i18n.global.t;
const props = defineProps<Props>();
const isLastStep = computed(() => props.currentStep === props.totalSteps);
</script>
